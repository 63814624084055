<template>
  <div v-if="dialog">
    <v-dialog
      v-model="dialog"
      @click:outside="closeModal"
      @keydown.esc="closeModal"
    >
      <v-card class="pa-10 pa-md-12">
        <v-card-title class="mt-2 pa-0 d-flex justify-center">
          <div class="font-weight-bold text-h2" style="word-break: break-word">
            Detalles del paquete contratado
          </div>
          <v-btn
            icon
            :ripple="false"
            absolute
            right
            top
            class="btn-background-transparent hover-close-btn"
            @click="closeModal"
          >
            <v-icon large color="secondary">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="mt-13 mt-md-16 pa-0 black--text">
          <v-row class="mt-5">
            <v-col cols="12" class="pr-sm-10 text-body-1">
              <div class="text-h3 font-weight-bold mb-6">Descripción</div>
              <div class="text-pre-line">
                {{ packageInfo.package.description }}
              </div>
              <div class="my-6">
                <div class="my-5 d-flex d-md-block align-center">
                  <v-icon
                    class="mr-3 secondary--text"
                    x-large
                    :class="
                      packageInfo.package.corkage
                        ? 'far fa-check-circle'
                        : 'far fa-times-circle'
                    "
                  />
                  <span>
                    {{
                      packageInfo.package.corkage
                        ? "Ofrece descorche para proveedores externos."
                        : "No ofrece descorche para provedores externos."
                    }}
                  </span>
                </div>
                <div class="my-5 d-flex d-md-block align-center">
                  <v-icon
                    class="mr-3 secondary--text"
                    x-large
                    :class="
                      packageInfo.package.extraHour
                        ? 'far fa-check-circle'
                        : 'far fa-times-circle'
                    "
                  />
                  <span>
                    {{
                      packageInfo.package.extraHour
                        ? "Ofrece horas extras."
                        : "No ofrece horas extras."
                    }}
                  </span>
                </div>
                <div class="my-5 d-flex d-md-block align-center">
                  <v-icon class="mr-3 secondary--text" x-large>
                    far fa-check-circle
                  </v-icon>
                  <span>
                    Solicita
                    {{
                      numberToCurrency(packageInfo.package.paymentAdvance / 100)
                    }}
                    anticipo para reservar fecha.
                  </span>
                </div>
                <div class="my-5 d-flex d-md-block align-center">
                  <v-icon class="mr-3 secondary--text" x-large>
                    far fa-check-circle
                  </v-icon>
                  <span>
                    Solicita liquidación del paquete
                    {{ packageInfo.package.paymentDeadline }}
                    {{
                      packageInfo.package.paymentDeadline == 1 ? "día" : "días"
                    }}
                    previos al evento.
                  </span>
                </div>
                <div class="mt-5 d-flex d-md-block align-center">
                  <v-icon class="mr-3 secondary--text" x-large>
                    fas fa-users
                  </v-icon>
                  <span>
                    {{ packageInfo.package.kidsQuantity }}
                    {{
                      packageInfo.package.kidsQuantity === 1 ? "niño" : "niños"
                    }}
                    -
                    {{ packageInfo.package.adultsQuantity }}
                    {{
                      packageInfo.package.adultsQuantity === 1
                        ? "adulto"
                        : "adultos"
                    }}
                  </span>
                </div>
              </div>
            </v-col>
            <v-col cols="12">
              <div class="text-h3 font-weight-bold mb-5">
                Elementos que incluye
              </div>
              <v-row no-gutters>
                <v-col cols="12" md="6" lg="4">
                  <div
                    class="d-flex justify-space-between font-weight-bold text-body-1 mb-5"
                  >
                    <span>Elemento</span>
                    <span>Cant.</span>
                  </div>
                  <div v-if="packageInfo.package.packageItems.length > 0">
                    <div
                      v-for="item in packageInfo.package.packageItems"
                      :key="'packageItem' + item.id"
                      class="d-flex justify-space-between text-body-1 mb-1"
                    >
                      <span>{{ item.name }}</span>
                      <span>{{ item.quantity }}</span>
                    </div>
                  </div>
                  <div class="d-flex justify-space-between text-body-1 mb-1">
                    <span>Platillos niños</span>
                    <span>{{ packageInfo.package.kidsQuantity }}</span>
                  </div>
                  <div class="d-flex justify-space-between text-body-1 mb-1">
                    <span>Platillos adultos</span>
                    <span>{{ packageInfo.package.adultsQuantity }}</span>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <div class="text-h3 font-weight-bold mb-5">
                Platillos seleccionados
              </div>
              <v-row
                v-if="packageInfo.package.adultsQuantity > 0"
                :class="packageInfo.package.kidsQuantity > 0 && 'mb-5'"
                no-gutters
              >
                <v-col cols="12" md="6" lg="4">
                  <div
                    class="d-flex justify-space-between font-weight-bold text-body-1 mb-5"
                  >
                    <span>Adultos</span>
                    <span>Cant.</span>
                  </div>
                  <div
                    v-for="item in packageInfo.reservationDishes.filter(
                      (d) => d.isForAdult
                    )"
                    :key="'reservationDish' + item.id"
                    class="d-flex justify-space-between text-body-1 mb-1"
                  >
                    <span>{{ item.name }}</span>
                    <span>{{ item.quantity }}</span>
                  </div>
                </v-col>
              </v-row>
              <v-row v-if="packageInfo.package.kidsQuantity > 0" no-gutters>
                <v-col cols="12" md="6" lg="4">
                  <div
                    class="d-flex justify-space-between font-weight-bold text-body-1 mb-5"
                  >
                    <span>Niños</span>
                    <span>Cant.</span>
                  </div>

                  <div
                    v-for="item in packageInfo.reservationDishes.filter(
                      (d) => !d.isForAdult
                    )"
                    :key="'reservationDish' + item.id"
                    class="d-flex justify-space-between text-body-1 mb-1"
                  >
                    <span>{{ item.name }}</span>
                    <span>{{ item.quantity }}</span>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { numberToCurrency } from "@/Utils/numberFormatter.js";

export default {
  name: "ModalPackageInfo",
  props: {
    dialog: { type: Boolean, required: true },
    packageInfo: { type: Object, required: true },
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    numberToCurrency(number) {
      return numberToCurrency(number);
    },
  },
};
</script>

<style scoped lang="scss">
.btn-background-transparent::before {
  background-color: transparent !important;
}

.hover-close-btn:hover .v-icon {
  transform: scale(1.1);
}
</style>
