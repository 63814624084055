<template>
  <div>
    <div class="fill-height purple-gradient">
      <Navbar page="provider" />
    </div>
    <div class="body-container">
      <div
        v-if="reservation && !loading"
        :style="[
          $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
            ? { 'padding-top': '30px' }
            : { 'padding-top': '60px' },
        ]"
      >
        <v-container class="px-10 px-md-auto">
          <div class="text-h1 font-weight-bold mb-4">Recibo</div>
          <div class="d-flex flex-column flex-md-row mt-2 ml-1">
            <div class="text-h4 mr-sm-10">
              No. de confirmación: {{ reservation.id }}
            </div>
          </div>
          <Button
            text="Volver"
            pill
            :height="44"
            outlined
            prepend-icon="fa-caret-left"
            class="mt-11"
            aria-label="Volver a lista de reservaciones"
            url="ProviderReservations"
          />
          <v-row class="mt-12">
            <v-col cols="12" md="6">
              <v-card
                elevation="0"
                class="mx-1 pa-7 text-h4 rounded-lg blue-border flex-grow-1"
                height="100%"
              >
                <div class="pb-1 text-h3 font-weight-bold">
                  Información del cliente
                </div>
                <div class="mt-4">
                  {{ reservation.userName }}
                </div>
                <div class="mt-4">
                  {{ reservation.eventName }}
                </div>
                <div class="mt-4">
                  {{ reservation.userEmail }}
                </div>
                <div class="mt-4">
                  {{ reservation.userPhone }}
                </div>
                <div class="mt-4">
                  {{ reservation.userCity }}, {{ reservation.userState }}
                </div>
              </v-card>
            </v-col>
            <v-col cols="12" md="6">
              <v-card
                elevation="0"
                class="mx-1 pa-7 text-h4 rounded-lg blue-border"
                height="100%"
              >
                <div class="pb-1 text-h3 font-weight-bold">
                  Información del proveedor
                </div>
                <div class="mt-4">
                  <router-link
                    class="black--text text-decoration-underline"
                    :to="getProviderRoute"
                  >
                    {{ reservation.providerName }}
                  </router-link>
                </div>
                <div class="mt-4">
                  <router-link
                    class="black--text text-decoration-underline"
                    :to="getPackageRoute"
                  >
                    {{ reservation.packageName }}
                  </router-link>
                </div>
                <div class="mt-4">
                  {{ providerAddress }}
                </div>
                <div class="mt-4">
                  {{ reservation.providerPhone }}
                </div>
                <div class="mt-4">
                  {{ reservation.providerEmail }}
                </div>
              </v-card>
            </v-col>
          </v-row>
          <div class="my-14">
            <div class="mx-7 text-h4">
              <div class="mb-5 mb-md-0">
                <div class="text-h3 font-weight-bold">
                  Desglose del paquete contratado
                </div>
              </div>
              <div class="ml-1">
                <v-row class="align-center mt-6" no-gutters>
                  <v-col cols="12" sm="5" md="4">
                    <span>Costo de paquete (IVA incluido)</span>
                  </v-col>
                  <v-col cols="12" sm="7" md="8">
                    <span>
                      {{ numberToCurrency(reservation.packageCost) }}
                    </span>
                  </v-col>
                </v-row>
                <v-row
                  v-if="reservation.extraHours !== 0"
                  class="align-center mt-5"
                  no-gutters
                >
                  <v-col cols="12" sm="5" md="4">
                    <span>Hora extra ({{ reservation.extraHours }})</span>
                  </v-col>
                  <v-col cols="12" sm="7" md="8">
                    <span>
                      {{
                        numberToCurrency(
                          reservation.extraHours * reservation.extraHourCost
                        )
                      }}
                    </span>
                  </v-col>
                </v-row>
                <v-row
                  v-if="reservation.extraAdults !== 0"
                  class="align-center mt-5"
                  no-gutters
                >
                  <v-col cols="12" sm="5" md="4">
                    <span>Persona extra ({{ reservation.extraAdults }})</span>
                  </v-col>
                  <v-col cols="12" sm="7" md="8">
                    <span>
                      {{
                        numberToCurrency(
                          reservation.extraAdults * reservation.extraAdultCost
                        )
                      }}
                    </span>
                  </v-col>
                </v-row>
                <!-- <v-row
                  v-if="reservation.extraKids !== 0"
                  class="align-center mt-5"
                  no-gutters
                >
                  <v-col cols="12" sm="5" md="4">
                    <span>Niño extra ({{ reservation.extraKids }})</span>
                  </v-col>
                  <v-col cols="12" sm="7" md="8">
                    <span>
                      {{
                        numberToCurrency(
                          reservation.extraKids * reservation.extraKidCost
                        )
                      }}
                    </span>
                  </v-col>
                </v-row> -->
                <v-row
                  v-if="reservation.corkageCost !== 0"
                  class="align-center mt-5"
                  no-gutters
                >
                  <v-col cols="12" sm="5" md="4">
                    <span>Descorche para proveedores</span>
                  </v-col>
                  <v-col cols="12" sm="7" md="8">
                    <span>
                      {{ numberToCurrency(reservation.corkageCost) }}
                    </span>
                  </v-col>
                </v-row>
                <v-row
                  v-for="(
                    suppItem, index
                  ) in reservation.reservationSupplementaryItems"
                  :key="'suppItem' + index"
                  class="align-center mt-5"
                  no-gutters
                >
                  <v-col cols="12" sm="5" md="4">
                    <span>
                      {{ suppItem.name }} ({{ suppItem.unit }}) ({{
                        suppItem.quantity
                      }})
                    </span>
                  </v-col>
                  <v-col cols="12" sm="7" md="8">
                    <span>
                      {{ numberToCurrency(suppItem.quantity * suppItem.price) }}
                    </span>
                  </v-col>
                </v-row>
                <v-row class="align-center mt-5" no-gutters>
                  <v-col cols="12" sm="5" md="4">
                    <span>Comisión Zaturna (5%)</span>
                  </v-col>
                  <v-col cols="12" sm="7" md="8">
                    <span>
                      {{ numberToCurrency(reservation.zaturnaPayment) }}
                    </span>
                  </v-col>
                </v-row>
                <v-row class="align-center mt-5" no-gutters>
                  <v-col cols="12" sm="5" md="4">
                    <span class="font-weight-bold">Total</span>
                  </v-col>
                  <v-col cols="12" sm="7" md="8">
                    <span class="font-weight-bold">
                      {{ numberToCurrency(reservation.totalPayment) }}
                    </span>
                  </v-col>
                </v-row>
                <v-row class="align-center mt-5" no-gutters>
                  <v-col cols="12" sm="5" md="4">
                    <span>Anticipo</span>
                  </v-col>
                  <v-col cols="12" sm="7" md="8">
                    <span>
                      {{
                        numberToCurrency(
                          reservation.reservationPayments[0].total
                        )
                      }}
                    </span>
                  </v-col>
                </v-row>
              </div>
              <v-row class="align-center mt-4" no-gutters>
                <v-col cols="12" sm="5" md="4">
                  <span>Detalles del paquete</span>
                </v-col>
                <v-col cols="12" sm="7" md="8">
                  <Button
                    text="Ver"
                    aria-label="Ver información detallada del paquete contratado"
                    @event="handlePackageInfoModal()"
                  />
                </v-col>
              </v-row>
            </div>
          </div>
          <div class="pb-14 mx-7">
            <div class="mt-10 mb-md-8 mb-5 mb-md-0">
              <div class="text-h3 font-weight-bold">Plan de pagos</div>
            </div>
            <v-row
              v-for="(pays, index) in reservation.reservationPayments"
              :key="'pymnts' + index"
              no-gutters
              class="my-4"
            >
              <v-col cols="12" md="4">
                <div>{{ getText(index) }}</div>
              </v-col>
              <v-col cols="12" md="3">
                <div>{{ numberToCurrency(pays.total) }} MXN</div>
              </v-col>
              <v-col cols="12" md="5">
                <div>{{ dateFormatter(pays.date) }}</div>
              </v-col>
            </v-row>
            <v-row
              v-if="reservation.status !== 'paid'"
              no-gutters
              class="my-2 my-sm-4"
            >
              <v-col cols="12" md="4">
                <div>Liquidación</div>
              </v-col>
              <v-col cols="12" md="3">
                <div>
                  {{
                    numberToCurrency(
                      reservation.totalPayment - reservation.totalPaid
                    )
                  }}
                  MXN
                </div>
              </v-col>
              <v-col cols="12" md="5">
                <div
                  v-if="reservation.status === 'confirmed'"
                  class="font-italic"
                >
                  <span> Fecha límite: </span>
                  {{ dateFormatter(reservation.paymentDeadline) }}
                </div>
              </v-col>
            </v-row>
            <v-row no-gutters class="mt-4 pl-1">
              <v-col cols="12" md="4">
                <div>Total pagado</div>
              </v-col>
              <v-col cols="12" md="3">
                <div>
                  {{ numberToCurrency(reservation.totalPaid) }}
                  MXN
                </div>
              </v-col>
            </v-row>
          </div>
          <div class="pb-14 mx-7">
            <div class="mt-10 mb-md-8 mb-5 mb-md-0">
              <div class="text-h3 font-weight-bold">
                Políticas de cancelación
              </div>
              <div class="d-flex flex-wrap mt-6">
                <p class="mr-16 pr-3">
                  Permitir cancelación con
                  {{ reservation.package.cancellationDeadline }} días previos al
                  evento.
                </p>
                <p>
                  Reembolso del
                  <span class="font-weight-bold">
                    {{ reservation.reimbursement }}%
                  </span>
                  por cancelación de servicio.
                </p>
              </div>
            </div>
          </div>
        </v-container>
      </div>
      <v-container v-else>
        <InlineLoading />
      </v-container>
    </div>
    <Footer />
    <ModalPackageInfo
      v-if="provider && !loading"
      :dialog="packageInfoModal"
      :package-info="reservation"
      @closeModal="handlePackageInfoModal"
    />
  </div>
</template>

<script>
import Navbar from "@/components/Provider/Shared/Navbar.vue";
import Button from "@/components/Shared/Button.vue";
import InlineLoading from "@/components/Shared/InlineLoading.vue";
import Footer from "@/components/Shared/Footer.vue";
import ModalPackageInfo from "@/components/Shared/Modal/ModalPackageInfo.vue";
import { mapState } from "vuex";
import { GET_PACKAGE_RESERVATION } from "@/graphql/queries";
import { useQuery } from "@/graphql/index";
import { numberToCurrency } from "@/Utils/numberFormatter.js";
import { convertUnixToDDMMYYYY } from "@/Utils/dateConverter.js";
import { formatProviderAddress } from "@/Utils/Utils";

export default {
  name: "PackageReservationDetails",
  components: {
    Navbar,
    Button,
    InlineLoading,
    Footer,
    ModalPackageInfo,
  },
  data: () => ({
    reservation: {
      reservationPayments: [],
    },
    loading: true,
    packageInfoModal: false,
    reservationFetching: false,
    reservationFetched: false,
  }),
  computed: {
    ...mapState({
      provider(state) {
        return state.provider;
      },
    }),
    providerAddress() {
      const provider = {
        city: this.reservation.providerCity,
        district: this.reservation.providerDistrict,
        externalNumber: this.reservation.providerExternalNumber,
        internalNumber: this.reservation.providerInternalNumber,
        state: this.reservation.providerState,
        street: this.reservation.providerStreet,
        zipCode: this.reservation.providerZipCode,
      };
      return formatProviderAddress(provider);
    },
    getProviderRoute() {
      return {
        name: "UserProvider",
        params: {
          id: this.reservation.package.providerId,
        },
      };
    },
    getPackageRoute() {
      return {
        name: "UserPackage",
        params: {
          id: this.reservation.package.id,
        },
      };
    },
  },
  watch: {
    provider: {
      async handler(provider) {
        if (!provider) return;
        if (!this.reservationFetching && !this.reservationFetched) {
          this.reservationFetching = true;
          await this.fetchReservation();
          this.reservationFetched = true;
          this.reservationFetching = false;
        }
      },
      immediate: true,
    },
  },
  methods: {
    async fetchReservation() {
      const props = {
        packageReservationId: parseInt(this.$route.params.id),
        clientId: parseInt(this.provider.id),
        route: "provider",
      };

      try {
        const { data, errors } = await useQuery(GET_PACKAGE_RESERVATION, props);

        if (errors) throw errors;

        this.reservation = data.packageReservation;
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.$router.push({ name: "ProviderReservations" });
      }
    },
    handlePackageInfoModal() {
      this.packageInfoModal = !this.packageInfoModal;
    },
    getText(index) {
      return index === 0
        ? "Anticipo"
        : index == this.reservation.reservationPayments.length - 1 &&
          this.reservation.status === "paid"
        ? "Liquidación "
        : "Pago " + index;
    },
    dateFormatter(unixTime) {
      return convertUnixToDDMMYYYY(unixTime);
    },
    numberToCurrency(number) {
      return numberToCurrency(number / 100);
    },
  },
};
</script>

<style scoped>
.purple-gradient {
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0) 0%,
    rgba(98, 37, 130, 1) 100%
  );
  background-size: cover !important;
}
.blue-border {
  border: 1px solid var(--v-secondary-base);
}
</style>
